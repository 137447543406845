'use client';

import useThemeMode from '@/lib/hooks/useThemeMode';
import { css, Global } from '@emotion/react';
import { useTheme } from '@mui/material';

export default function GlobalStyles() {
  const theme = useTheme();

  const globalStyles = css`
    * {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
    }

    html,
    body {
      max-width: 100vw;
      overflow: hidden;
      height: 100%;
    }

    main {
      scroll-behavior: smooth;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    .json-pretty {
      font-size: 1.3rem;
    }
    .__json-string__ {
      color: ${theme.palette.text.primary};
    }
    .__json-boolean__ {
      color: ${theme.palette.secondary.main};
    }
    .__json-key__ {
      color: ${theme.palette.primary.main};
    }
    @media (prefers-color-scheme: dark) {
      html {
        color-scheme: ${theme.palette.mode};
      }
    }

    @media only screen and (max-width: 600px) {
      .json-pretty {
        font-size: 1.1rem;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: none !important;
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: ${theme.palette.text.primary};
    }
    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
  `;

  return <Global styles={globalStyles} />;
}
