"use client";

import texts from "@/lib/context/language/texts";
import useLanguage from "@/lib/hooks/useLanguage";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import DarkMode from "@mui/icons-material/DarkMode";
import MuiImage from "@/components/muiImage";
import useThemeMode from "@/lib/hooks/useThemeMode";
import LightMode from "@mui/icons-material/LightMode";
import Code from "@mui/icons-material/Code";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Instagram from "@mui/icons-material/Instagram";
import GitHub from "@mui/icons-material/GitHub";
import Divider from "@mui/material/Divider";
import Copyright from "@mui/icons-material/Copyright";
import WhatsApp from "@mui/icons-material/WhatsApp";
import { useEffect, useRef, useState } from "react";
import Close from "@mui/icons-material/Close";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Menu from "@mui/icons-material/Menu";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Home from "@mui/icons-material/Home";
import Description from "@mui/icons-material/Description";
import AutoAwesome from "@mui/icons-material/AutoAwesome";
import Handshake from "@mui/icons-material/Handshake";
import AccountTree from "@mui/icons-material/AccountTree";
import ContactMail from "@mui/icons-material/ContactMail";
import { useTheme } from "@mui/material";

export default function GlobalLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const { language, changeLanguage } = useLanguage();
  const colorMode = useThemeMode();
  const {
    palette: { mode },
  } = useTheme();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const screenTexts = texts[language].globalLayout;

  const handleScroll = (mainContainer: HTMLElement | null) => {
    if (mainContainer) {
      const position = mainContainer.scrollTop;

      setScrollPosition(position);
    }
  };

  const toggleDrawer = (newOpen: boolean) => () => setOpenDrawer(newOpen);

  useEffect(() => {
    const mainContainer = document.getElementById("main-container");

    mainContainer?.addEventListener(
      "scroll",
      () => handleScroll(mainContainer),
      { passive: true }
    );

    return () =>
      window.removeEventListener("scroll", () => handleScroll(mainContainer));
  }, []);

  const mainRef = useRef({} as HTMLDivElement);

  const selectFlags = {
    ["pt-BR"]: "https://flagcdn.com/16x12/br.png",
    ["en-US"]: "https://flagcdn.com/16x12/us.png",
  };

  const colorModeIcon = {
    dark: <DarkMode />,
    light: <LightMode />,
  };

  const resetToTop = () => {
    const mainContainer = document.getElementById("main-container");
    if (mainContainer) {
      mainContainer?.scrollTo({ top: 0 });
    }
  };

  const headerButtons = [
    {
      label: screenTexts.buttons.homeLabel,
      icon: <Home />,
      href: "/#home",
      key: "home",
    },
    {
      label: screenTexts.buttons.aboutLabel,
      icon: <Description />,
      href: "/#about",
      key: "about",
    },
    {
      label: screenTexts.buttons.hardSkillsLabel,
      icon: <AutoAwesome />,
      href: "/#hardSkills",
      key: "hardSkill",
    },
    {
      label: screenTexts.buttons.softSkillsLabel,
      icon: <Handshake />,
      href: "/#softSkills",
      key: "softSkill",
    },
    {
      label: screenTexts.buttons.projectsLabel,
      icon: <AccountTree />,
      href: "/#projects",
      key: "projects",
    },
    {
      label: screenTexts.buttons.contactLabel,
      icon: <ContactMail />,
      href: "/#contact",
      key: "contact",
    },
  ];

  const colorModeLabel = {
    dark: "Dark",
    light: "Light",
  };

  const languageLabel = {
    "en-US": "English",
    "pt-BR": "Portuguese",
  };

  return (
    <Box width="100%" height="100%">
      <AppBar position="static" sx={{ bgcolor: "background.paper" }}>
        <Toolbar>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Link href="/#home">
              <Box display="flex" alignItems="center" gap={1}>
                <Code fontSize="large" color="primary" />
                <Typography variant="h6">Ezequiel Lara</Typography>
              </Box>
            </Link>
            <Box
              display={{ xs: "none", sm: "flex" }}
              alignItems="center"
              gap={2}
            >
              {headerButtons.map((button) => (
                <Link key={button.key} href={button.href}>
                  <Button color="primary">{button.label}</Button>
                </Link>
              ))}
            </Box>
            <Box display={{ xs: "none", sm: "flex" }} gap={2}>
              <IconButton
                sx={{ bgcolor: "background.paper" }}
                onClick={colorMode.toggleColorMode}
                aria-label={`Color mode: ${colorModeLabel[mode]}`}
                type="button"
              >
                {colorModeIcon[mode]}
              </IconButton>
              <IconButton
                sx={{ width: 40 }}
                aria-label={`Language: ${languageLabel[language]}`}
                onClick={changeLanguage}
              >
                <MuiImage
                  alt="flag"
                  src={selectFlags[language]}
                  width={16}
                  height={12}
                />
              </IconButton>
            </Box>
            <Box display={{ xs: "flex", sm: "none" }}>
              <IconButton aria-label="Menu" onClick={toggleDrawer(true)}>
                <Menu />
              </IconButton>
              <Drawer
                open={openDrawer}
                onClose={toggleDrawer(false)}
                anchor="right"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width={250}
                  role="presentation"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    px={1}
                  >
                    <Box display="flex" gap={1}>
                      <IconButton
                        onClick={colorMode.toggleColorMode}
                        aria-label={`Color mode: ${colorModeLabel[mode]}`}
                      >
                        {colorModeIcon[mode]}
                      </IconButton>
                      <IconButton
                        onClick={changeLanguage}
                        aria-label={`Language: ${languageLabel[language]}`}
                      >
                        <MuiImage
                          alt="flag"
                          src={selectFlags[language]}
                          width={16}
                          height={12}
                        />
                      </IconButton>
                    </Box>
                    <IconButton
                      onClick={toggleDrawer(false)}
                      aria-label="Close"
                    >
                      <Close />
                    </IconButton>
                  </Box>
                  <Divider />
                  <List>
                    {headerButtons.map((button) => (
                      <Link key={button.key} href={button.href}>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>{button.icon}</ListItemIcon>
                            <ListItemText primary={button.label} />
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Box>
              </Drawer>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        id="main-container"
        ref={mainRef}
        component="main"
        overflow="auto"
        maxHeight={{ xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }}
        height="100%"
        position="relative"
      >
        {children}
        <Box
          width="100%"
          bgcolor="background.paper"
          p={4}
          display="flex"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={4}
            width="100%"
          >
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "center", sm: "flex-start" }}
              gap={{ xs: 4, sm: 8 }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems={{ xs: "center", sm: "flex-start" }}
                gap={4}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={{ xs: "center", sm: "flex-start" }}
                >
                  <Typography variant="body1" fontWeight={700}>
                    {screenTexts.footer.texts.emailLabel}
                  </Typography>
                  <Typography variant="subtitle2">
                    ezeq.uiel@hotmail.com
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={{ xs: "center", sm: "flex-start" }}
                >
                  <Typography variant="body1" fontWeight={700}>
                    {screenTexts.footer.texts.phoneLabel}
                  </Typography>
                  <Typography variant="subtitle2">+55 48 9 84377172</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems={{ xs: "center", sm: "flex-start" }}
              >
                <Typography variant="body1" fontWeight={700}>
                  {screenTexts.footer.texts.mediasLabel}
                </Typography>
                <Box>
                  <Link
                    href="https://www.linkedin.com/in/ezequiel-lara-/"
                    target="_blank"
                  >
                    <IconButton>
                      <LinkedIn fontSize="small" />
                    </IconButton>
                  </Link>
                  <Link href="https://github.com/EzequielZK" target="_blank">
                    <IconButton>
                      <GitHub fontSize="small" />
                    </IconButton>
                  </Link>
                  <Link
                    href="https://www.instagram.com/ezequielsonn/"
                    target="_blank"
                  >
                    <IconButton>
                      <Instagram fontSize="small" />
                    </IconButton>
                  </Link>
                  <Link href="https://w.app/d2OmIn" target="_blank">
                    <IconButton>
                      <WhatsApp fontSize="small" />
                    </IconButton>
                  </Link>
                </Box>
              </Box>
            </Box>
            <Divider flexItem />
            <Box display="flex" gap={1} alignItems="center">
              <Copyright fontSize="small" />
              <Typography variant="caption">
                {screenTexts.footer.texts.rights}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Fade in={scrollPosition > 400}>
          <Tooltip title={screenTexts.buttons.scrollToTopTooltip}>
            <IconButton
              color="secondary"
              size="large"
              sx={{
                position: "fixed",
                bottom: { xs: 10, sm: 20 },
                right: { xs: 20, sm: 50 },
              }}
              onClick={resetToTop}
            >
              <KeyboardArrowUp fontSize="large" />
            </IconButton>
          </Tooltip>
        </Fade>
      </Box>
    </Box>
  );
}
