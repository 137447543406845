'use client';

import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { createContext } from 'react';
import { ColorMode, ThemeProps } from './types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';
import { getCookie, setCookie } from 'cookies-next';

export const ThemeContext = createContext({ toggleColorMode: () => {} });

export default function Theme({ children }: ThemeProps) {
  const cookieMode = getCookie('colorMode') as ColorMode;

  const [mode, setMode] = useState<ColorMode>(cookieMode ?? 'dark');

  const [mounted, setMounted] = useState(false);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        const newMode = mode === 'light' ? 'dark' : 'light';
        setCookie('colorMode', newMode);
        setMode(newMode);
      },
    }),
    [mode]
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  const theme = useMemo(() => {
    let mainTheme = createTheme({
      palette: {
        mode,
        ...(mode === 'dark'
          ? {
              text: {
                primary: '#fff',
              },
              primary: {
                main: '#00ffff',
                contrastText: '#000',
              },
              secondary: {
                main: '#c426c4',
                contrastText: '#000',
              },
              action: {
                disabledBackground: 'rgba(255,255,255,0.12)',
                disabled: 'rgba(255,255,255,0.3)',
              },
              background: {
                default: '#0a0a0a',
                paper: '#121212',
              },
            }
          : {
              text: {
                primary: '#000',
              },
              primary: {
                main: '#007070',
              },
              secondary: {
                main: '#AB21AB',
              },
              action: {
                disabledBackground: 'rgba(255,255,255,0.12)',
                disabled: 'rgba(255,255,255,0.3)',
                active: 'rgba(0,0,0,0.54)',
              },
              background: {
                default: '#fff',
                paper: '#f7f7f7',
              },
            }),
      },
      typography: {
        fontFamily: [
          "'Inter Tight'",
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      spacing: 8,
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1360,
          xl: 1600,
        },
      },
    });

    mainTheme = createTheme(
      mainTheme,
      {
        components: {
          MuiStack: {
            defaultProps: {
              direction: 'row',
            },
          },
          MuiTypography: {
            styleOverrides: {
              root: {
                color: mainTheme.palette.text.primary,

                wordBreak: 'break-word',
                lineHeight: 1.25,
              },
            },
          },
          MuiContainer: {
            defaultProps: {
              maxWidth: 'lg',
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundImage: 'none',
              },
            },
          },
        },
      },
      ptBR
    );
    return mainTheme;
  }, [mode]);

  if (!mounted) {
    return null;
  }

  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
