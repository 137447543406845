'use client';

import { useContext } from 'react';
import { ThemeContext } from '../context/theme';

export default function useThemeMode() {
  const colorMode = useContext(ThemeContext);

  return colorMode;
}
